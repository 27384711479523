<template>
  <div v-if="authUserIsAdmin">
    <div>
      <LoadingMessage v-if="isLoading" :message="'Fetching items...'"></LoadingMessage>

      <ErrorMessage v-if="error" :error="error" class="my-3"></ErrorMessage>

      <form
        @submit.prevent
        v-if="!isLoading && !error"
        class="d-flex align-items-center bg-dark p-2 rounded"
      >
        <div class="form-floating-cta-container">
          <div class="form-floating-cta">
            <fa-icon
              @click="resetFilter"
              v-if="keyword.length"
              :icon="['fas', 'times-circle']"
              class="text-white"
              role="button"
            ></fa-icon>
          </div>
          <input
            v-model.trim="keyword"
            ref="keyword"
            type="text"
            class="form-control form-control-dark"
            placeholder="Search item..."
          >
        </div>
        <IconButton
          @click="add"
          :icon="'plus'"
          class="btn btn-primary ms-2"
        ></IconButton>
        <IconButton
          @click="refresh"
          :icon="'sync-alt'"
          class="btn btn-primary ms-2"
        ></IconButton>
      </form>

      <div v-if="!isLoading && !error" class="rounded border bg-white mt-2">
        <div class="table-responsive">
          <table class="table m-0">
            <thead>
              <tr>
                <th scope="col" class="align-baseline">Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!pagedItems.length">
                <td>No exercises found</td>
              </tr>
              <AdminLibraryManagerItem
                @addOrUpdate="addOrUpdateItem($event)"
                @delete="deleteItem($event)"
                v-for="(item, itemIdx) in pagedItems"
                :key="`key-${itemIdx}`"
                :item="item"
              ></AdminLibraryManagerItem>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <nav v-if="!isLoading && !error && totalPage > 1" class="d-flex justify-content-center">
      <ul class="pagination mt-5 mb-0">
        <li
          @click="currentPage = index"
          v-for="index in totalPage"
          :key="`key-${index}`"
          :class="{ 'disabled': index === currentPage }"
          class="page-item"
          role="button"
        >
          <a class="page-link">
            <span :class="{ 'is-busy': index === currentPage }">{{ index }}</span>
          </a>
        </li>
      </ul>
    </nav>

    <portal to="modal">
      <transition name="modal">
        <AdminLibraryModal
          @addOrUpdate="addOrUpdateItem($event)"
          @close="showExerciseLibraryModal = false"
          v-model="showExerciseLibraryModal"
          v-if="showExerciseLibraryModal"
        ></AdminLibraryModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'AdminLibraryManager',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    AdminLibraryManagerItem: () => import('@/components/admin/AdminLibraryManagerItem'),
    AdminLibraryModal: () => import('@/components/admin/AdminLibraryModal'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
    filteredItems() {
      let arr = [];
      const { keyword, items } = this;

      const q = keyword.trim().toLowerCase();
      if (q === '') {
        arr = items;
      } else {
        arr = items.filter((item) => {
          const { name } = item;
          return name.toLowerCase().indexOf(q) >= 0;
        });
      }

      return arr;
    },
    pagedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.filteredItems.slice(startIndex, endIndex);
    },
    totalPage() {
      const { filteredItems, perPage } = this;
      if (filteredItems.length) {
        return Math.ceil(filteredItems.length / perPage);
      }
      return 0;
    },
  },
  watch: {
    keyword() {
      this.currentPage = 1;
    },
  },
  methods: {
    add() {
      this.showExerciseLibraryModal = true;
    },
    refresh() {
      this.fetch();
    },
    async fetch() {
      this.isLoading = true;
      this.error = null;
      this.keyword = '';
      this.currentPage = 1;
      try {
        const res = await httpGet('/admin/library');
        this.items = res.data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    addOrUpdateItem(newItem) {
      const index = this.items.findIndex((item) => {
        const { exerciseLibraryId } = item;
        return exerciseLibraryId === newItem.exerciseLibraryId;
      });
      if (index > -1) {
        this.items.splice(index, 1, newItem);
      } else {
        this.items.push(newItem);
      }
    },
    deleteItem(itemId) {
      const index = this.items.findIndex((item) => item.exerciseLibraryId === itemId);
      if (index > -1) this.items.splice(index, 1);
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
  },
  data() {
    return {
      showExerciseLibraryModal: false,
      isLoading: false,
      error: null,
      items: [],
      activeItem: null,
      keyword: '',
      perPage: 25,
      currentPage: 1,
    };
  },
  mounted() {
    this.fetch();
  },
};
</script>
